.uploader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
}

.dropzone {
  width: 100%;
  max-width: 1000px;
  min-height: 250px;
  background-color: #fafafa;
  border: 3px dashed #ccc;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.dropzone:hover {
  border-color: #999;
}

.preview-container {
  width: 100%;
  max-width: 1000px;
  margin-top: 20px;
  padding: 20px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 20px;
}

/* Image preview grid */
.image-preview-grid {
  margin-top: 20px;
}

.image-preview-item {
  margin-bottom: 16px;
}

/* Image preview card styling */
.image-preview-item .MuiCard-root {
  display: flex;
  align-items: center;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.MuiCard-root img {
  margin-bottom: 5px;
}

.submit-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .dropzone {
    max-width: 100%;
    padding: 20px;
    min-height: 150px;
  }

  .preview-container {
    max-width: 100%;
    padding: 15px;
  }

  .image-preview-item .MuiCard-root {
    flex-direction: column;
  }

  .image-preview-item img {
    margin-bottom: 10px;
    width: 80px; /* Smaller image on mobile */
  }

  .submit-container {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .dropzone {
    padding: 15px;
  }

  .preview-container {
    padding: 10px;
  }

  .image-preview-item img {
    width: 60px; /* Even smaller image for very small screens */
  }
}

/* ------------------------------ */
/* .uploader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
}

.dropzone {
  width: 100%;
  max-width: 900px;
  min-height: 200px;
  background-color: #fafafa;
  border: 3px dashed #ccc;
  border-radius: 8px;
  transition: all 0.3s ease-in-out; 
  cursor: pointer;
}

.dropzone:hover {
  border-color: #999; 
}

.preview-container {
  width: 100%;
  max-width: 900px;
  margin-top: 20px;
  padding: 20px;
  background-color: #fafafa;
  border: 2px solid #ccc;
  border-radius: 8px;
}

.image-preview-grid {
  margin-top: 20px;
}

.image-preview-grid img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.submit-container {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .dropzone {
    max-width: 100%;
    padding: 20px;
    min-height: 150px;
  }

  .preview-container {
    max-width: 100%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .dropzone {
    padding: 15px;
  }

  .preview-container {
    padding: 10px;
  }
}
------------------------------- */
/* Uploader container
.uploader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
}

.dropzone {
  width: 100%;
  max-width: 900px;
  min-height: 200px;
  background-color: #fafafa;
  border: 3px dashed #ccc; 
  border-radius: 8px;
  padding: 40px;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
  border-style: dashed;
  border-width: 4px; 
  border-color: #ccc;
  border-radius: 8px;
  background-color: #fafafa;
  background-clip: padding-box;
  border-image: none;
  border-image-source: none;
  border-spacing: 12px;
  border-left-width: 4px;
  border-right-width: ;
}
.dropzone:hover {
  border-color: #999; 
}

@media (max-width: 768px) {
  .dropzone {
    max-width: 100%;
    padding: 20px;
    min-height: 150px;
  }
} */
