@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: "Inter", sans-serif;
}
