/* Full page container */
.loginPage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginPage-container {
  height: 100%;
}

.loginPage-left {
  background-image: url("../assets/auth-side-bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.loginPage-image-overlay {
  background-color: rgba(0, 0, 0, 0.4); /* Dark overlay on image */
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
}

.loginPage-right-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.loginPage-right {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Logo Styling */
.loginPage-logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.loginPage-logo {
  /* max-width: 150px;
  width: 100%;
  height: auto; */
}

/* Input Fields */
.login-input {
  margin-bottom: 16px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .loginPage-left {
    display: none; /* Hide the left section on small screens */
  }

  .loginPage-right-container {
    width: 100%;
    padding: 10px;
  }

  .loginPage-right {
    max-width: 100%;
    padding: 15px;
    box-shadow: none; /* Remove box shadow on small screens */
  }

  .loginPage-logo {
    max-width: 100px; /* Smaller logo on mobile */
  }
}

@media (max-width: 480px) {
  .loginPage-right {
    padding: 10px;
  }

  .loginPage-logo {
    max-width: 80px; /* Even smaller logo for very small screens */
  }
}
